import { useCallback, useEffect, useState } from "react";
import { Button, useMediaQuery } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import {
  getCategoryBreadCrumbs,
  getChidrenLetterAndIndex,
  getMainCategoryColor,
} from "../../utils/categories";
import alphabet from "../../utils/alphabet";
import { useAppSelector } from "../../store/hook";
import {
  Category,
  CategoryChildren,
  GetCategory,
} from "../../types/api/category";
import { LetterIndex } from "../../types/letter";

import ArrowRight from "../../assets/icons/arrows/right";

import NoData from "../../components/NoData";
import Loader, { StyledCircularProgress } from "../../components/Loader";
import CustomBreadcrumbs from "../../components/CustomBreadcrumbs";

import "./Categories.css";
import { AxiosError } from "axios";
import { setHasNoNetwork } from "../../store/reducers/auth.slice";
import SearchBar from "../../components/home/SearchBar";
import api from "../../utils/api/axios";
import { get, set } from "idb-keyval";

const Categories = () => {
  const isSmallScreen = useMediaQuery("(max-width: 768px)");
  const navigate = useNavigate();
  const { categoryId } = useParams();
  const auth = useAppSelector((state) => state.auth);
  const { hasNoNetwork, user } = auth;

  const [isLoading, setIsLoading] = useState(false);
  const [visibleLetter, setVisibleLetter] = useState<string>("A");
  const [category, setCategory] = useState<Category>();
  const [color, setColor] = useState<string>("navy");
  const [accessibleCategories, setAccessibleCategories] = useState<number[]>(
    []
  );
  const [categoryChildren, setCategoryChildren] = useState<CategoryChildren[]>(
    []
  );
  const [filteredCategoryChildren, setFilteredCategoryChildren] = useState<
    CategoryChildren[]
  >([]);
  const [childrenLetterAndIndex, setChildrenLetterAndIndex] =
    useState<LetterIndex>();
  const firstLettersRefs: { [key: number]: HTMLElement | null } = {};
  const lettersRefs: { [key: string]: HTMLElement | null } = {};

  const goToLetter = (letter: string) => {
    const firstLetterIndex = childrenLetterAndIndex?.get(letter);
    if (firstLetterIndex === undefined) return;

    const categoryChild = firstLettersRefs[firstLetterIndex];
    if (!categoryChild) return;

    const top = categoryChild.offsetTop;
    const topOffset = top - 30;
    window.scrollTo({ top: topOffset, behavior: "smooth" });
  };

  const onScroll = () => {
    // scroll to first letter in list
    const entriesFirstLetters = Object.entries(firstLettersRefs);
    const firstVisible = entriesFirstLetters.find(
      ([, ref]) =>
        ref && ref.getBoundingClientRect().top >= (isSmallScreen ? 60 : 110)
    );
    const firstVisibleLetter = firstVisible?.[1]?.ariaLabel || "";
    setVisibleLetter(firstVisibleLetter);

    // scroll to letter in letter filter on small screen
    const alphabetDiv = document.getElementById("categories-alphabet");
    const letterRef = lettersRefs[firstVisibleLetter];
    if (!alphabetDiv || !letterRef) return;

    const letterOffset = letterRef.getBoundingClientRect();
    const alphabetOffset = alphabetDiv.getBoundingClientRect();
    if (letterOffset.right > alphabetOffset.right) {
      alphabetDiv.scrollLeft += letterOffset.right - alphabetOffset.right + 30;
    }
    if (letterOffset.left < alphabetOffset.left) {
      alphabetDiv.scrollLeft -= alphabetOffset.left - letterOffset.left + 30;
    }
  };

  const navigateTo = (
    id: number,
    type: "sheet" | "subcategory" | "vaccin",
    isDisabled: boolean
  ) => {
    if (isDisabled) return;

    if (type === "vaccin") {
      navigate(`/vaccin/${id}`);
    } else if (type === "subcategory") {
      navigate(`/categorie/${id}`);
    } else {
      navigate(`/fiche/${id}`);
    }
  };

  // const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setSearch(e.target.value);
  // };

  const displayCategory = useCallback((categoryInfos: GetCategory) => {
    const categoryChildren = categoryInfos?.children ?? [];
    setCategory(categoryInfos?.category);
    setColor(getMainCategoryColor(categoryInfos?.mainCategoryId));
    setCategoryChildren(categoryChildren);
    setFilteredCategoryChildren(categoryChildren);
    setChildrenLetterAndIndex(getChidrenLetterAndIndex(categoryChildren));
    setAccessibleCategories(
      categoryInfos && !categoryInfos.category.parent
        ? categoryChildren
            .filter((child) => child.type === "sheet")
            .slice(0, 2)
            .map((sheet) => sheet.id)
        : []
    );
  }, []);

  const saveCategory = useCallback(async (categoryInfos: GetCategory) => {
    set("category-" + categoryInfos.category.id, categoryInfos);
  }, []);

  const fetchDataOffline = useCallback(
    async (categoryIdNumber: number): Promise<boolean> => {
      const categoryInfos = await get<GetCategory>(
        "category-" + categoryIdNumber
      );
      if (!categoryInfos) return false;
      displayCategory(categoryInfos);
      return true;
    },
    [displayCategory]
  );

  const fetchDataOnline = useCallback(
    async (categoryIdNumber: number) => {
      const { data: categoryInfos } = await api.get<GetCategory>(
        `/category/${categoryIdNumber}`
      );
      displayCategory(categoryInfos);
      saveCategory(categoryInfos);
    },
    [displayCategory, saveCategory]
  );

  const fetchData = useCallback(async () => {
    if (!categoryId) return;
    const categoryIdNumber = parseInt(categoryId);

    setIsLoading(true);
    const offlineSuccess = await fetchDataOffline(categoryIdNumber);
    if (offlineSuccess) {
      setIsLoading(false);
    }
    try {
      await fetchDataOnline(categoryIdNumber);
    } catch (e) {
      if (e instanceof AxiosError) {
        if (e.status === 500) dispatch(setHasNoNetwork(true));
      }
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId]);

  useEffect(() => {
    fetchData();
  }, [fetchData, hasNoNetwork]);

  useEffect(() => {
    onScroll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredCategoryChildren]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstLettersRefs]);

  const { allAccess } = user || {};

  return (
    <div className="categories-container">
      {category?.id === 4 && (
        <Button
          className={`background-${color}`}
          sx={{
            color: "white",
            "&:hover": {
              backgroundColor: color,
            },
          }}
          variant="contained"
          size="large"
          onClick={() => {
            navigate(`/mentorpro/`);
          }}
        >
          <div>
            <p className="mentor-pro-button">MentorPro</p>
            <p>Outil d'aide à la décision vaccinale de MesVaccins.net</p>
          </div>
        </Button>
      )}
      <CustomBreadcrumbs
        links={category ? getCategoryBreadCrumbs(category, true) : []}
      />

      <div>
        <div className="categories-title__container">
          <h1 className={["categories-title", color].join(" ")}>
            {isLoading ? (
              <StyledCircularProgress size={24} />
            ) : (
              category?.name || "Catégorie"
            )}
          </h1>

          <div className="categories-search">
            <SearchBar bordered={true} />
          </div>
        </div>

        <div id="categories-alphabet" className="categories-alphabet-container">
          {alphabet.map((l) => (
            <p
              key={l}
              ref={(ref) => (lettersRefs[l] = ref)}
              className={[
                "categories-alphabet-item",
                !childrenLetterAndIndex?.has(l)
                  ? "disabled"
                  : visibleLetter === l
                  ? color
                  : "",
              ].join(" ")}
              onClick={() => goToLetter(l)}
            >
              {l}
            </p>
          ))}
        </div>

        <div className="categories-result">
          {isLoading ? (
            <Loader />
          ) : categoryChildren.length === 0 ? (
            <NoData text="Aucune fiche" />
          ) : (
            filteredCategoryChildren.map((item, index) => {
              const isDisabled =
                !allAccess && accessibleCategories.indexOf(item.id) === -1;
              return (
                <div
                  key={`${item.id}-${item.name}`}
                  ref={(ref) => (firstLettersRefs[index] = ref)}
                  aria-label={item.name[0].toUpperCase()}
                  className={[
                    "categories-result-item",
                    item.type,
                    isDisabled ? "disabled" : "",
                  ].join(" ")}
                  onClick={() => {
                    navigateTo(item.id, item.type, isDisabled);
                  }}
                >
                  <p>{item.name}</p>
                  {item.type === "subcategory" && <ArrowRight color={color} />}
                </div>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default Categories;
function dispatch(arg0: any) {
  throw new Error("Function not implemented.");
}
