import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Button, useMediaQuery } from "@mui/material";
import dayjs from "dayjs";

import api from "../../utils/api/axios";
import {
  getCategoryBreadCrumbs,
  getMainCategoryColor,
} from "../../utils/categories";
import {
  getOldCategoryRedirection,
  getOldSheetRedirection,
} from "../../utils/legacy";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import { GetSheet, PlanPart, Sheet, SheetPart } from "../../types/api/sheet";
import { Category } from "../../types/api/category";

import ArrowRightTailed from "../../assets/icons/arrows/right-tailed";
import key from "../../assets/icons/key.svg";

import CustomBreadcrumbs from "../../components/CustomBreadcrumbs";
import Loader, { StyledCircularProgress } from "../../components/Loader";
import NoData from "../../components/NoData";
import Part from "../../components/sheet/Part";
import CustomModal from "../../components/CustomModal";

import "./Sheet.css";
import { get, set } from "idb-keyval";
import { AxiosError } from "axios";
import { setHasNoNetwork } from "../../store/reducers/auth.slice";

interface PlanPartState extends PlanPart {
  isOpen?: boolean;
}

const SheetPage = () => {
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery("(max-width: 1024px)");

  const dispatch = useAppDispatch();

  const auth = useAppSelector((state) => state.auth);
  const { hasNoNetwork, user, isAuth } = auth;
  const isEstablishment = user?.role === "ESTABLISHMENT";

  const { sheetId } = useParams();
  const [searchParams] = useSearchParams();
  const legacySheetId = searchParams.get("id");
  const legacyCatId = searchParams.get("cat_id");

  const [isLoading, setIsLoading] = useState(true);
  const [color, setColor] = useState<string>("navy");
  const [visibleIndex, setVisibleIndex] = useState<number>();
  const [mainCategoryName, setMainCategoryName] = useState<string>();
  const [sheet, setSheet] = useState<Sheet>();
  const [sheetParts, setSheetParts] = useState<SheetPart[]>([]);
  const [planParts, setPlanParts] = useState<PlanPartState[]>([]);
  const [categories, setCategories] = useState<Category>();
  const [hasNoAccess, setHasNoAccess] = useState<boolean>(false);
  const sheetPartsRefs: { [key: number]: HTMLElement | null } = {};

  const scrollToPart = (id: number) => {
    setPlanParts(
      planParts.map((part) => ({
        ...part,
        isOpen: part.id === id ? true : false,
      }))
    );
    const part = sheetPartsRefs[id];
    if (part) {
      const top = part.offsetTop;
      const topOffset = top - 80;
      window.scrollTo({ top: topOffset, behavior: "smooth" });
    }
  };

  const onScroll = () => {
    const entries = Object.entries(sheetPartsRefs);
    const firstVisible = entries.find(
      ([, ref]) => ref && ref.getBoundingClientRect().top >= 10
    );
    const firstVisibleIndex = firstVisible ? parseInt(firstVisible[0]) : 0;
    setVisibleIndex(firstVisibleIndex);
  };

  const getRedirection = async (
    legacyCategoryId: string,
    legacySheetId: string | null
  ) => {
    try {
      if (legacySheetId) {
        const sheetId = await getOldSheetRedirection(
          legacySheetId,
          legacyCategoryId
        );
        if (sheetId) navigate(`/fiche/${sheetId}`);
      } else {
        const categoryId = await getOldCategoryRedirection(legacyCategoryId);
        if (categoryId) navigate(`/categorie/${categoryId}`);
      }
    } catch (_) {}
  };

  const displaySheet = useCallback((sheetInfos: GetSheet) => {
    const parts = sheetInfos.planParts.reduce(
      (acc, planPart) => {
        const sheetPart = sheetInfos.sheetParts?.find(
          (p) => p.planId === planPart.id
        );
        if (sheetPart && sheetPart.content !== null && planPart.isActive) {
          acc.push({ sheetPart, planPart });
        }
        return acc;
      },
      [] as {
        sheetPart: SheetPart;
        planPart: PlanPart;
      }[]
    );
    const sheetParts = parts.map((part) => part.sheetPart);
    const planParts = parts.map((part) => part.planPart);
    setSheet(sheetInfos.sheet);
    setColor(getMainCategoryColor(sheetInfos.mainCategory.id));
    setMainCategoryName(sheetInfos.mainCategory.name);
    setSheetParts(sheetParts);
    setPlanParts(planParts);
    setVisibleIndex(planParts[0] ? planParts[0].id : undefined);
    setCategories(sheetInfos.categories);
    setHasNoAccess(sheetInfos.hasAllowedAccess === false);
  }, []);

  const saveSheet = useCallback(async (sheetInfos: GetSheet) => {
    await set("sheet-" + sheetInfos.sheet.id, sheetInfos);
  }, []);

  const fetchDataOnline = useCallback(
    async (sheetIdNumber: number) => {
      const { data: sheetInfos } = await api.get<GetSheet>(
        `/sheet/pwa/${sheetIdNumber}`
      );
      displaySheet(sheetInfos);
      saveSheet(sheetInfos);
    },
    [displaySheet, saveSheet]
  );

  const fetchDataOffline = useCallback(
    async (sheetIdNumber: number): Promise<boolean> => {
      const sheetInfos = await get<GetSheet>("sheet-" + sheetIdNumber);
      if (!sheetInfos) return false;
      displaySheet(sheetInfos);
      return true;
    },
    [displaySheet]
  );

  const fetchData = useCallback(async () => {
    if (!sheetId) return;
    const sheetIdNumber = parseInt(sheetId ?? "0");
    setIsLoading(true);
    const offlineSuccess = await fetchDataOffline(sheetIdNumber);

    if (offlineSuccess) {
      setIsLoading(false);
    }

    try {
      await fetchDataOnline(sheetIdNumber);
    } catch (e) {
      if (e instanceof AxiosError) {
        if (e.status === 500) dispatch(setHasNoNetwork(true));
      }
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sheetId]);

  useEffect(() => {
    fetchData();
  }, [fetchData, hasNoNetwork]);

  useEffect(() => {
    if (legacyCatId) getRedirection(legacyCatId, legacySheetId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [legacySheetId, legacyCatId]);

  useEffect(() => {
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sheetPartsRefs]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const uniquePlan =
    planParts.length === 1 && planParts[0].name === "(Partie unique générale)";

  return (
    <div className="sheet-container">
      {!uniquePlan && (
        <div className="sheet-plan">
          {planParts.map((part) => (
            <div
              key={part.id}
              className={[
                "sheet-plan__part",
                visibleIndex === part.id ? "visible" : "",
              ].join(" ")}
              onClick={() => scrollToPart(part.id)}
            >
              <p className={`sheet-plan__part--text text-${color}`}>
                {part.name}
              </p>
              <ArrowRightTailed
                className={"sheet-plan__part--img"}
                color={color}
              />
            </div>
          ))}
        </div>
      )}

      <div className="sheet-content">
        <CustomBreadcrumbs
          links={
            categories && sheet
              ? [...getCategoryBreadCrumbs(categories), { text: sheet.name }]
              : []
          }
        />

        <div className="sheet-title__container">
          {isLoading ? <StyledCircularProgress size={30} /> : null}
          {sheet && (
            <>
              <p className={`sheet-main-category background-${color}`}>
                {mainCategoryName}
              </p>
              <div className="sheet-title__content">
                <h1 className={["sheet-title"].join(" ")}>{sheet.name}</h1>
                <p className="sheet-date">
                  {dayjs(sheet.updatedAt).format("[mise à jour le] DD/MM/YYYY")}
                </p>
              </div>
            </>
          )}
        </div>

        <div className="sheet-parts-container">
          {isLoading ? (
            <Loader />
          ) : !sheetParts.length ? (
            <NoData />
          ) : (
            sheetParts.map((part, index) => {
              const planPart = planParts[index];
              return (
                <div
                  key={part.id}
                  ref={(ref) => (sheetPartsRefs[part.planId] = ref)}
                >
                  <Part
                    defaultOpen={
                      (!isSmallScreen && index === 0) || planPart.isOpen
                    }
                    name={planPart.name}
                    content={part.content as string}
                    color={color}
                  />
                </div>
              );
            })
          )}
        </div>
      </div>

      <CustomModal open={hasNoAccess || (hasNoAccess && !isAuth)} size="small">
        <div className="sheet-modal__title--container">
          <img className="sheet-modal__title--icon" src={key} alt="icon clé" />
          <p className="sheet-modal__title">Acces premium</p>
        </div>

        <p className="sheet-modal__text">
          {isEstablishment
            ? "Votre établissement n'a pas accès à cette fiche"
            : isAuth
            ? "Vous n'avez pas d'abonnement actif"
            : "Pour accéder à cette fiche, veuillez vous identifier"}
        </p>

        <div className="sheet-modal__button--container">
          {!isAuth ? (
            <>
              <Button
                variant="outlined"
                size="large"
                onClick={() => navigate("/connexion/inscription")}
              >
                S'inscrire
              </Button>
              <Button
                variant="contained"
                size="large"
                onClick={() => navigate("/connexion/se-connecter")}
              >
                Se connecter
              </Button>
            </>
          ) : isEstablishment ? (
            <Button
              variant="contained"
              size="large"
              onClick={() => navigate("/")}
            >
              Revenir à l'accueil
            </Button>
          ) : (
            <Button
              variant="contained"
              size="large"
              onClick={() => navigate("/mon-compte/abonnement")}
            >
              Gérer mon abonnement
            </Button>
          )}
        </div>
      </CustomModal>
    </div>
  );
};

export default SheetPage;
