import { get, keys } from "idb-keyval";
import { BreadcrumbsLink } from "../components/CustomBreadcrumbs";
import {
  GetAllDataCategory,
  GetAllDataSheet,
  GetAllDataVaccins,
} from "../types/api/allDatas";
import { Category, CategoryChildren, GetCategory } from "../types/api/category";

export const getCategoryBreadCrumbs = (
  category: Category,
  firstLoop: boolean = false
): BreadcrumbsLink[] => {
  let breadcrumbs: BreadcrumbsLink[] = [
    {
      to: !firstLoop ? `/categorie/${category.id}` : undefined,
      text: category.name,
    },
  ];
  if (category.parent) {
    const newBreadcrunbs = getCategoryBreadCrumbs(category.parent);
    breadcrumbs = newBreadcrunbs.concat(breadcrumbs);
  }

  return breadcrumbs;
};

export const getMainCategoryColor = (
  id: number | undefined,
  background?: boolean
) => {
  switch (id) {
    case 1:
      return "red";
    case 2:
      return "purple";
    case 3:
      return "green";
    case 4:
      return "blue";
    default:
      return background ? "white" : "navy";
  }
};

export const getChidrenLetterAndIndex = (children: CategoryChildren[]) => {
  const letterDone: string[] = [];
  const lettersAndIndexes: [string, number][] = [];
  children.forEach((child, index) => {
    const nameFirstLetter = child.name[0].toUpperCase();
    if (!letterDone.includes(nameFirstLetter)) {
      letterDone.push(nameFirstLetter);
      lettersAndIndexes.push([nameFirstLetter, index]);
    }
  });

  return new Map(lettersAndIndexes);
};

/**
 * Categories from strore construct fonctions like backend
 * Usefull for saved data in offline mode
 */
export const getCategoryParent = async (
  category: GetAllDataCategory
): Promise<Category> => {
  const parentCategory = category.parentId
    ? await get<GetAllDataCategory>("category" + category.parentId)
    : undefined;
  return {
    id: category.id,
    name: category.name,
    parent: parentCategory ? await getCategoryParent(parentCategory) : null,
  };
};

export const getMainCategory = (category: Category): Category => {
  if (!category.parent) return category;
  return getMainCategory(category.parent);
};

export const constructCategoryFromStore = async (
  categoryId: number
): Promise<GetCategory | undefined> => {
  const category = await get<GetAllDataCategory>("category-" + categoryId);
  if (!category) return;

  const categoryWithParent = await getCategoryParent(category);
  const mainCategory = getMainCategory(categoryWithParent);
  const keys_ = await keys();
  let childrenCategories: CategoryChildren[] = [];
  let childrenSheets: CategoryChildren[] = [];

  if (category.id === 4) {
    await Promise.all(
      keys_.map(async (key) => {
        if (key.toString().startsWith("v")) {
          const child = await get<GetAllDataVaccins>(key);
          childrenSheets.push({
            id: child.id,
            name: child.name,
            type: "vaccin",
          });
        }
      })
    );
  }

  await Promise.all(
    keys_.map(async (key) => {
      if (key.toString().startsWith("c")) {
        const child = await get<GetAllDataCategory>(key);
        if (child.parentId === category.id) {
          childrenCategories.push({
            id: child.id,
            name: child.name,
            type: "subcategory",
          });
        }
      }
      if (key.toString().startsWith("s")) {
        const child = await get<GetAllDataSheet>(key);
        if (child.categoryId === category.id) {
          childrenSheets.push({
            id: child.id,
            name: child.name,
            type: "sheet",
          });
        }
      }
    })
  );
  const children = [...childrenCategories, ...childrenSheets].sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  return {
    category: categoryWithParent,
    mainCategoryId: mainCategory.id,
    children,
    planParts: category.planParts,
  };
};

export const constructVaccinCategory = async () => {
  const keys_ = await keys();
  let childrenSheets: CategoryChildren[] = [];
  await Promise.all(
    keys_.map(async (key) => {
      if (key.toString().startsWith("v")) {
        const child = await get<GetAllDataVaccins>(key);
        childrenSheets.push({
          id: child.id,
          name: child.name,
          type: "vaccin",
        });
      }
    })
  );
  return childrenSheets;
};
