import { useEffect } from "react";
import { RouterProvider } from "react-router-dom";

import router from "./routes/router";

import api, { removeToken, setToken } from "./utils/api/axios";
import { notifyApiSuccess, notifyApiWarning } from "./utils/api/notifyApi";
import { UserLogin, UserRole } from "./types/api/user";
import { GetAllData } from "./types/api/allDatas";
import { set, clear } from "idb-keyval";

import { useAppDispatch, useAppSelector } from "./store/hook";
import { setAuthUser, setHasNoNetwork } from "./store/reducers/auth.slice";

function App() {
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);
  const { isAuth, hasNoNetwork, user } = auth;

  // init to false to avoid succes message on first load

  const fetchAllDatas = async () => {
    try {
      const { data } = await api.get<GetAllData | boolean>("/all-data");

      // is case subscription is not active
      if (!data || typeof data === "boolean") {
        if (data === false) {
          clear();
        }
        return;
      }
      clear();
      const { categories, sheets, vaccins } = data;

      for (const sheet of sheets) {
        if (sheet) await set("sheet-" + sheet.sheet.id, sheet);
      }
      for (const category of categories) {
        if (category) await set("category-" + category.category.id, category);
      }
      for (const vaccin of vaccins) {
        if (vaccin) await set("vaccin-" + vaccin.id, vaccin);
      }
      notifyApiSuccess("Votre mode hors ligne est opérationnel");
    } catch (_) {}
  };

  const checkUserIP = async () => {
    try {
      const { data } = await api.post<UserLogin | boolean | undefined>(
        "/auth/ip/login"
      );
      if (data && typeof data !== "boolean") {
        notifyApiSuccess("Vous êtes connecté via votre IP");
        dispatch(setAuthUser(data));
        setToken(data.token.token);
      } else if (user?.role === UserRole.ESTABLISHMENT && data !== true) {
        try {
          await api.post("/auth/logout");
        } catch (_) {
          console.error("Logout failed");
        }
        removeToken();
      }
    } catch (_) {}

    fetchAllDatas();
  };

  useEffect(() => {
    if (hasNoNetwork) {
      notifyApiWarning("Vous n'avez pas de connexion internet");
    } else {
      notifyApiSuccess("Vous êtes connecté à internet");
      checkUserIP();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasNoNetwork, isAuth]);

  useEffect(() => {
    if (hasNoNetwork) {
      const intervalId = setInterval(async () => {
        try {
          await api.get("/network-test");
        } catch (_) {
          dispatch(setHasNoNetwork(true));
        }
      }, 5000);

      return () => clearInterval(intervalId);
    }
  }, [hasNoNetwork, dispatch]);

  return <RouterProvider router={router} />;
}

export default App;
